/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('button.menu-button').on('click', function (event) {
          $('html').toggleClass('menu-open');
        });
        $('aside.sidebar h3').on('click', function(event) {
          $('aside.sidebar h3 + ul').toggleClass('opened');
        });

        $('aside.sidebar > section > ul > li > a, aside.sidebar > section > ul > li > ul > li > a').on('click', function(event){
          var $child = $(this).next('ul');
          if($child.length) {
            event.preventDefault();
            $child.toggleClass('opened');
          }
        });
        if(!$('.menu-button').is(':visible')) {
          //$('aside.sidebar .current-cat-ancestor .current-cat-ancestor').parent().addClass('opened');
          //$('aside.sidebar .current-cat').parent().addClass('opened');
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $(window).on('load resize', function () {
          $('.intro').height($(window).height());
        });
        $(window).on('load', function () {
          $('.intro').addClass('fit');
          // $('.main-visual .image').velocity({'top': 0, 'left': 0, width: '100%', height: '100%'}, {duration: 2000, delay: 500, easing: 'ease'});
          // $('.main-visual .copy').velocity('fadeIn', {duration: 1000, delay: 2500});

//          $('.main-visual').addClass('fit');
          $('.intro .scroll-button').velocity('fadeIn', {duration: 1000, delay: 0});
        });

        function scrollEvents() {
          var scroll = $(window).scrollTop();
          var winHeight = $(window).height();
          if (scroll >= winHeight / 2) {
            $("header.banner").addClass('show');
          } else {
            $("header.banner").removeClass('show');
          }
          if (scroll < winHeight) {
            $('.intro .image').css({'top': scroll/3});
          }
        }

        $(window).on('load scroll', scrollEvents);
        $('body').on('touchmove', scrollEvents);

        $('.intro .scroll-button').on('click', function (event) {
          event.preventDefault();
          if($('.slider-wrap').is(':visible')) {
            $('.slider-wrap').velocity('scroll', {duration: 1000, offset: -1 * $('header.banner').height()});
          } else {
            $('.slider-wrap + .wrap.container').velocity('scroll', {duration: 1000, offset: -1 * $('header.banner').height()});
          }
        });

        // JavaScript to be fired on the home page
        var mySwiper = new Swiper ('.swiper-container', {
          // Optional parameters
          direction: 'horizontal',
          loop: true,
          speed: 1000,
          autoplay: {delay: 3000},
          slidesPerView: 'auto',
          centeredSlides: true,
          paginationClickable: true,
          spaceBetween: 10
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('a[href^="#"]').on('click', function(event) {
          // Make sure this.hash has a value before overriding default behavior
          if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
              scrollTop: $(hash).offset().top
            }, 800, function(){

              // Add hash (#) to URL when done scrolling (default click behavior)
              window.location.hash = hash;
            });
          } // End if
        });
      }
    },
    'recruit': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('a[href^="#"]').on('click', function(event) {
          // Make sure this.hash has a value before overriding default behavior
          if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
              scrollTop: $(hash).offset().top
            }, 800, function(){

              // Add hash (#) to URL when done scrolling (default click behavior)
              window.location.hash = hash;
            });
          } // End if
        });
      }
    },
    'knowledge': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('a[href^="#"]').on('click', function(event) {
          // Make sure this.hash has a value before overriding default behavior
          if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
              scrollTop: $(hash).offset().top
            }, 800, function(){

              // Add hash (#) to URL when done scrolling (default click behavior)
              window.location.hash = hash;
            });
          } // End if
        });
      }
    },

    'front_page': {
      init: function() {
        //console.log("front");
      }
    },
    'tax_qanda_category': {
      init: function() {
        $('.open-button, header>h2').on('click', function(event) {
          event.preventDefault();
          $(this).parents('article').toggleClass('open');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
